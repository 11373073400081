import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/components/NavBar.scss';
import VentoryLogo from './VentoryLogo';
import { useAuth } from '../hooks';
import { MaxWidthContainer } from '../components'


export default function NavBar(props) {
  const { profile, register } = props;
  const navigate = useNavigate();
  const auth = useAuth();

  const [profileButtonClicked, setProfileButtonClicked] = useState(false);

  const handleToggleProfileButton = () => {
    if (profile) {setProfileButtonClicked(!profileButtonClicked);}
  }

  useEffect(() => {
    if (!auth.user) {setProfileButtonClicked(false);}
  }, [auth])

  return (
    <nav className="nav-bar">
      <VentoryLogo />

      <div className="nav-bar-right">
        {(profile && auth.user) && (
          <div
            className="profile-icon"
            onClick={handleToggleProfileButton}
          >
            <i className="fa-solid fa-shop icon"></i>
          </div>
        )}

        {(register && !auth.user) && (
          <button
            className="register-btn"
            onClick={() => navigate('/register')}
          >
            <p>REGISTER NOW!</p>
          </button>
        )}
      </div>

      {(profileButtonClicked) && (
        <MaxWidthContainer>
          <div className="profile-tab">
            <button className="logout-btn" onClick={auth.logout}>
              <p>Logout</p>
              <i className="fa-solid fa-power-off icon"></i>
            </button>
          </div>
        </MaxWidthContainer>
      )}
    </nav>
  );
}
