import '../../styles/Home/Carousel.scss';
import mainCarousalImage from '../../assets/home/carousel/main.png';
import SignIn from './SignIn';
import { useAuth } from '../../hooks';


export default function Carousel() {
  const auth = useAuth();

  return (
    <div
      className="carousel"
      style={{
        backgroundImage: `url(${mainCarousalImage})`
      }}
    >
      {(!auth.user) && (
        <div className="sign-in-container">
          <SignIn />
        </div>
      )}
    </div>
  );
}
