import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import '../styles/components/App.scss';
import { useAuth } from '../hooks';
import {
  DashboardProvider, RegisterProvider
} from '../providers';
import { VentoryLoader } from '../components';
import Home from './Home';
import Register from './Register';
import Dashboard from './Dashboard';
import LegalAgreements from './LegalAgreements';
import PlansAndPricing from './PlansAndPricing';


function PrivateRoute({ children, auth }){
  return auth.user ? (
    children
  ): (
    <Navigate to='/' />
  );
}


export default function App() {
  const auth = useAuth();

  if (auth.loading){
    return (
      <div className="app">
        <VentoryLoader height="100px" />
      </div>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/register"
          element={
            <RegisterProvider>
              <Register />
            </RegisterProvider>
          }
        />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute auth={auth}>
              <DashboardProvider>
                <Dashboard />
              </DashboardProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/legal-agreements/*"
          element={<LegalAgreements />}
        />

        <Route
          path="/plans-and-pricing"
          element={<PlansAndPricing />}
        />

        <Route
          path="*"
          element={<Navigate to="/"/>}
        />
      </Routes>
    </BrowserRouter>
  );
}