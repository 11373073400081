import '../../styles/Home/index.scss';
import Carousel from './Carousel';
import WhyVentory from './WhyVentory';
import WhyChooseUs from './WhyChooseUs';
import PartnersApp from './PartnersApp';
import CustomerApp from './CustomerApp';
import WhatElse from './WhatElse';
import {
  MaxWidthContainer, NavBar, Footer
} from '../../components'


export default function Home() {
  return (
    <div className="home">
      <div className="nav-bar-container">
        <MaxWidthContainer>
          <NavBar profile register />
        </MaxWidthContainer>
      </div>

      <div className="carousel-container">
        <MaxWidthContainer>
          <Carousel />
        </MaxWidthContainer>
      </div>

      <div className="section-container">
        <MaxWidthContainer>
          <WhyVentory />
        </MaxWidthContainer>
      </div>

      <div className="section-container">
        <MaxWidthContainer>
          <WhyChooseUs />
        </MaxWidthContainer>
      </div>

      <div className="section-container">
        <MaxWidthContainer>
          <PartnersApp />
        </MaxWidthContainer>
      </div>

      <div className="section-container">
        <MaxWidthContainer>
          <CustomerApp />
        </MaxWidthContainer>
      </div>

      <div className="section-container">
        <MaxWidthContainer>
          <WhatElse />
        </MaxWidthContainer>
      </div>

      <div className="footer-container">
        <MaxWidthContainer>
          <Footer />
        </MaxWidthContainer>
      </div>
    </div>
  );
}
