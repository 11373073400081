import { createContext } from 'react';
import { useProvideRegister } from '../hooks';

export const RegisterContext = createContext({
  stepsToRegisterInfo: [],
  currentStepNumber: 0,
  isLoading: false,
  goBackToPrevStep: () => {},
  moveToNextStep: () => {},
  isUserExists: false, // STEP-0
  removeIsUserExistsPrompt: () => {},
  handleEmailIdServerVerification: () => {},
  handleCheckEmailIdExists: () => {},
  handleCheckMobileNumberExists: () => {},
  handleMobileNumberServerVerification: () => {},
  shopGSTNumber: '', // STEP-1
  handleShopGSTNumberChange: () => {},
  shopOwnerName: '', // STEP-1
  handleShopOwnerNameChange: () => {},
  shopName: '',
  handleShopNameChange: () => {},
  availableCategoriesList: [],
  shopSubCategoryInputOption: '',
  shopCategory: '',
  handleSelectShopCategoryChange: () => {},
  shopSubCategory: '',
  handleSelectShopSubCategoryDropdownChange: () => {},
  handleSelectShopSubCategoryTextInputChange: () => {},
  resetShopCategoryInfo: () => {},
  aboutShop: '',
  handleAboutShopChange: () => {},
  shopCoverImage: null, // STEP-2
  setShopCoverImage: () => {},
  shopLogoImage: null,
  setShopLogoImage: () => {},
  shopCoverImageUrl: '',
  setShopCoverImageUrl: () => {},
  shopLogoImageUrl: '',
  setShopLogoImageUrl: () => {},
  moreImages: [],
  setMoreImages: () => {},
  shopThemeColors: {},
  setShopThemeColors: () => {},
  checkIsMoreImagesEmpty: () => {},
  checkIsMoreImagesFilled: () => {}
});

export const RegisterProvider = ({ children }) => {
  const register = useProvideRegister();

  return (
    <RegisterContext.Provider
      value={register}
    >
      {children}
    </RegisterContext.Provider>
  )
}