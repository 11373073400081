import '../../../styles/Register/InputGSTDetails/index.scss';
import { useRegister } from '../../../hooks';
import Input from './Input';


export default function InputGSTDetails() {
  const register = useRegister();
  const {
    shopGSTNumber, handleShopGSTNumberChange
  } = register;

  return (
    <div className="input-gst-details">
      <div className='input-gst-number-container'>
        <div className='gst-number-input-label'>
          <div></div>
        </div>

        <div className='gst-number-input'>
          <div className='input-section input-state-code'>
            {shopGSTNumber.slice(0, 2).map((GSTElement, GSTElementIndex) => (
              <Input
                key={`gst-number-input-state-code-${GSTElementIndex}`}
                value={GSTElement}
                onChange={(e) => handleShopGSTNumberChange(e, GSTElementIndex)}
              />
            ))}
          </div>

          <div className='input-section input-pan-number'>
            {/*{shopGSTNumber.slice(2, 12).map((GSTElement, GSTElementIndex) => (*/}
            {/*  <div*/}
            {/*    key={`input-state-code-${GSTElementIndex}`}*/}
            {/*    className="input-gst-box"*/}
            {/*  >*/}
            {/*    {GSTElement}*/}
            {/*  </div>*/}
            {/*))}*/}
          </div>

          <div className='input-section input-entity-number'>
            {/*{shopGSTNumber.slice(12, 13).map((GSTElement, GSTElementIndex) => (*/}
            {/*  <div*/}
            {/*    key={`input-state-code-${GSTElementIndex}`}*/}
            {/*    className="input-gst-box"*/}
            {/*  >*/}
            {/*    {GSTElement}*/}
            {/*  </div>*/}
            {/*))}*/}
          </div>

          <div className='input-section default-z-alphabet'>
            {/*{shopGSTNumber.slice(13, 14).map((GSTElement, GSTElementIndex) => (*/}
            {/*  <div*/}
            {/*    key={`input-state-code-${GSTElementIndex}`}*/}
            {/*    className="input-gst-box"*/}
            {/*  >*/}
            {/*    {GSTElement}*/}
            {/*  </div>*/}
            {/*))}*/}
          </div>

          <div className='input-section input-check-sum-digit'>
            {/*{shopGSTNumber.slice(14, 15).map((GSTElement, GSTElementIndex) => (*/}
            {/*  <div*/}
            {/*    key={`input-state-code-${GSTElementIndex}`}*/}
            {/*    className="input-gst-box"*/}
            {/*  >*/}
            {/*    {GSTElement}*/}
            {/*  </div>*/}
            {/*))}*/}
          </div>
        </div>
      </div>

      <div className='input-gst-doc-container'>

      </div>
    </div>
  );
}