import '../../styles/Register/index.scss';
import { useRegister } from '../../hooks';
import InputContainer from './InputContainer';
import VerifyCredentials from './VerifyCredentials';
import InputShopDetails from './InputShopDetails';
import InputShopImages from './InputShopImages';
import InputContactDetails from './InputContactDetails';
import InputGSTDetails from './InputGSTDetails';
import {
  MaxWidthContainer, Footer, NavBar
} from '../../components';


export default function Register() {
  const register = useRegister();
  const {
    stepsToRegisterInfo, currentStepNumber,
    goBackToPrevStep, moveToNextStep, isLoading
  } = register;

  return (
    <div className="register">
      <div className="nav-bar-container">
        <MaxWidthContainer>
          <NavBar profile />
        </MaxWidthContainer>
      </div>

      <div className="register__wrapper">
        <MaxWidthContainer>
          <InputContainer
            stepsToRegisterInfo={stepsToRegisterInfo}
            currentStepNumber={currentStepNumber}
            prevStepButtonOnClick={goBackToPrevStep}
            nextStepButtonOnClick={moveToNextStep}
            isLoading={isLoading}
          >
            { currentStepNumber === 1 ? (<InputGSTDetails />)
            : currentStepNumber === 2 ? (<InputShopDetails />)
            : currentStepNumber === 3 ? (<InputContactDetails />)
            : currentStepNumber === 4 ? (<div></div>)
            : currentStepNumber === 5 ? (<InputShopImages />)
            : (<VerifyCredentials />)}
          </InputContainer>
        </MaxWidthContainer>
      </div>

      <div className="footer-container">
        <MaxWidthContainer>
          <Footer />
        </MaxWidthContainer>
      </div>
    </div>
  );
}
