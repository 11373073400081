import { Link } from 'react-router-dom';
import '../styles/components/Footer.scss';
import makeInIndiaSvg from '../assets/home/make-in-india.svg';
import ventoryFullLogo from '../assets/shared/full-logo.png';

export default function Footer() {
  const EMAIL_REDIRECT_URL = 'mailto:contact@ventory.in'; // temp
  const MOBILE_REDIRECT_URL = 'tel:+918421888905'; // temp
  const WP_REDIRECT_URL = 'https://wa.me/+918421888905'; // temp

  return (
    <div className="footer">

      {/*top container-------------------------------------------------------*/}
      <div className="footer-top-container">
        {/*Getting Started-------------------------------------------------*/}
        <div className='footer-top-section footer-getting-started'>
          <h3>Getting Started</h3>
          <Link className='link' to='/plans-and-pricing'>
            Plans & Pricing
          </Link>
          <Link className='link' to='/how-to-use'>
            How to Use
          </Link>
          <Link className='link' to='/features'>
            Features
          </Link>
        </div>

        {/*Policies--------------------------------------------------------*/}
        <div className='footer-top-section footer-policies'>
          <h3>Policies</h3>
          <Link className="link" to="/legal-agreements/terms-and-conditions">
            Terms and Conditions
          </Link>
          <Link className="link" to="/legal-agreements/privacy-and-policy">
            Privacy and Policy
          </Link>
          <Link className="link" to="/legal-agreements/return-and-refund-policy">
            Return and Refund Policy
          </Link>
        </div>

        {/*Visit-----------------------------------------------------------*/}
        <div className='footer-top-section footer-visit'>
          <h3>Visit</h3>
          <Link className='link' to='/store'>
            Ventory Store
          </Link>
          <Link className='link' to='/our-partners'>
            Our Partners
          </Link>
        </div>

        {/*Services--------------------------------------------------------*/}
        <div className='footer-top-section footer-services'>
          <h3>Services</h3>
          <Link className='link' to='/ventory-for-chain-store'>
            Ventory for Chain Stores
          </Link>
          <Link className='link' to='/customize-your-inventory'>
            Customize your Inventory
          </Link>
        </div>

        {/*Others----------------------------------------------------------*/}
        <div className='footer-others'>
          <div className='footer-logos'>
            <img src={ventoryFullLogo} alt='ventory-empowering-business' />
            <img src={makeInIndiaSvg} alt='make-in-india' />
          </div>

          <div className='footer-make-in-india'>
            <a href='https://www.makeinindia.com' target='_blank'>
              Make in India
            </a>
          </div>

          <div className='footer-contact'>
            <h3>Contact us:</h3>
            <a href={EMAIL_REDIRECT_URL} target='_blank'>Email</a>
            <p>·</p>
            <a href={MOBILE_REDIRECT_URL} target='_blank'>Phone</a>
            <p>·</p>
            <a href={WP_REDIRECT_URL} target='_blank'>WhatsApp</a>
          </div>
        </div>
      </div>

      {/*Divider-------------------------------------------------------------*/}
      <div className="footer-divider"></div>

      {/*legal-copyright-----------------------------------------------------*/}
      <div className='footer-legal-copyright'>
        <p>©2024 Grig Technologies Pvt Ltd · Ventory® · All rights reserved.</p>
      </div>
    </div>
  );
}